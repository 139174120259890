import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19cf4f82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "node-body-main-header" }
const _hoisted_2 = { class: "level" }
const _hoisted_3 = {
  key: 0,
  class: "option"
}
const _hoisted_4 = { class: "node-body-main-content" }
const _hoisted_5 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_6 = {
  key: 2,
  class: "node-error"
}
const _hoisted_7 = { class: "node-footer" }
const _hoisted_8 = { class: "btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Share = _resolveComponent("Share")!
  const _component_app_ellipsis = _resolveComponent("app-ellipsis")!
  const _component_CopyDocument = _resolveComponent("CopyDocument")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_Close = _resolveComponent("Close")!
  const _component_ArrowRight = _resolveComponent("ArrowRight")!
  const _component_Warning = _resolveComponent("Warning")!
  const _component_insert_button = _resolveComponent("insert-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ node: true, 'node-error-state': _ctx.showError })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'node-body': true, error: _ctx.showError })
    }, [
      (_ctx.level > 1 && _ctx.$store.state.diagramMode !== 'viewer')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "node-body-left",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('leftMove')))
          }, [
            _createVNode(_component_el_icon, { size: "1em" }, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowLeft)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "node-body-main",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('selected')))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_icon, {
            size: "1.6em",
            color: "#15bca3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Share)
            ]),
            _: 1
          }),
          _createVNode(_component_app_ellipsis, {
            class: "title pl-1",
            "hover-tip": "",
            content: _ctx.config.name ? _ctx.config.name : '条件' + _ctx.level
          }, null, 8, ["content"]),
          _createElementVNode("span", _hoisted_2, "优先级" + _toDisplayString(_ctx.level), 1),
          (_ctx.diagramMode !== 'viewer')
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_el_tooltip, {
                  effect: "dark",
                  content: "复制条件",
                  placement: "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_icon, {
                      size: "1.3em",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('copy')), ["stop"]))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CopyDocument)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_icon, {
                  size: "1.3em",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('delNode')), ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Close)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          ((_ctx.content || '').trim() === '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.level === _ctx.size && _ctx.size !== 0 ? "其他条件进入此流程" : _ctx.placeholder), 1))
            : (_openBlock(), _createBlock(_component_app_ellipsis, {
                key: 1,
                hoverTip: "",
                row: 4,
                content: _ctx.content
              }, null, 8, ["content"]))
        ])
      ]),
      (_ctx.level < _ctx.size && _ctx.diagramMode !== 'viewer')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "node-body-right",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('rightMove')))
          }, [
            _createVNode(_component_el_icon, { size: "1em" }, {
              default: _withCtx(() => [
                _createVNode(_component_ArrowRight)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.showError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_el_tooltip, {
              effect: "dark",
              content: _ctx.errorInfo,
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { size: "1em" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Warning)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["content"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.diagramMode !== 'viewer')
          ? (_openBlock(), _createBlock(_component_insert_button, {
              key: 0,
              onInsertNode: _cache[5] || (_cache[5] = (type) => _ctx.$emit('insertNode', type))
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}